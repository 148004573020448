@import 'vendor/@madpaws/design-system/dist/tokens/tokens.css';

.root {
  --margin: calc($tokenSpacingLayoutGridMarginSmall * -1);

  margin-bottom: calc($tokenSpacingLarge4X + $tokenSpacingLarge6X);
  margin-left: var(--margin);
  margin-right: var(--margin);

  & .heading {
    margin-bottom: $tokenSpacingLarge1X;
    text-align: center;
  }

  & .teaser {
    align-items: center;
    aspect-ratio: 16 / 9;
    border-radius: $tokenBorderRadiusLarge4X;
    display: flex;
    justify-content: center;
    margin-bottom: $tokenSpacingLarge2X;
    margin-left: $tokenSpacingLarge1X;
    margin-right: $tokenSpacingLarge1X;
    overflow: hidden;
    position: relative;

    & .image {
      inset: 0;
      opacity: 0.8;
      position: absolute;
      width: 100%;
    }

    & .icon {
      --side: calc($tokenSpacingLarge6X + $tokenSpacingSmall2X);
      --iconIndex: 2;

      align-items: center;
      display: flex;
      height: var(--side);
      justify-content: center;
      position: relative;
      width: var(--side);

      & svg {
        color: $tokenColorsGreyscaleLight6X;
        fill: $tokenColorsGreyscaleWhite;
        margin-left: $tokenSpacingSmall3X;
        z-index: var(--iconIndex);
      }

      & .round {
        background-color: $tokenColorsGreyscaleBlack;
        border-radius: $tokenBorderRadiusLarge5X;
        height: var(--side);
        opacity: 0.6;
        position: absolute;
        width: var(--side);
        z-index: 1;
      }
    }
  }
}
